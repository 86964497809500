<template>
  <div class="background">
    <div class="info-network"
							@click="showNetwork = !showNetwork"
						>
							<div class="network-icon">
								<img id="network-name"
									src="../assets/img/icon/network.png"
									alt="icon"
								/>
							</div>
							<transition appear name="fade">
								<div class="network-popup" v-if="showNetwork">
									<div
										class="network-wrapper"
										@click="onClickItem('ETH')"
									>
										<img
											class="icon"
											src="../assets/img/ethereum.png"
											alt="icon"
										/>
										<input
											type="checkbox"
											id="Ethereum"
											value="Ethereum"
											:checked="checkedNetwork === 'Ethereum'"
										/>
										<label for="etherium">Ethereum</label>
									</div>
									<div
										class="network-wrapper"
										@click="onClickItem('BSC')"
										v-if="loginBy !== 'BITSKI'"
									>
										<img
											class="icon"
											src="../assets/img/bsc.svg"
											alt="icon"
										/>
										<input
											type="checkbox"
											id="BSC"
											value="BSC"
											:checked="checkedNetwork === 'BSC'"
										/>
										<label for="bsc">BSC</label>
									</div>
									<!-- <div
										class="network-wrapper"
										@click="onClickItem('POL')"
									>
										<img
											class="icon"
											src="../assets/img/polygon.svg"
											alt="icon"
										/>
										<input
											type="checkbox"
											id="Polygon"
											value="Polygon"
											:checked="checkedNetwork === 'Polygon'"
										/>
										<label for="Polygon">Polygon</label>
									</div> -->
								</div>
							</transition>
						</div>
    <img class="backgroundImg-Blur" />
    <div class="container">
      <img class="backgroundImg" />
      <div class="walletConnectPage" ref="walletConnectPage">
        <div class="info" ref="info">
          <div class="infoContainer">
            <img class="clickShowApprove" :src="approveImg" ref="approveImg" @click="clickShowApprove" />
            <div class="contractList">
              <ApproveContract  class="item" v-for="(item, idx) in addrs" :key="{idx}" :item="item" :number="idx + 1" :showApprove="showApprove" :operatorAddr="operatorAddr" />
            </div>
            <button class="goApp" @click="goApp"></button>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// 로그인 여부 체크, 안되어있으면 로그인 화면으로 이동
// 네트워크 선택
// 네트워크에 따라 컨트랙트 리스트 보여주기
// 컨트랙트 승인~

// window.localStorage.setItem('loginBy') 
// window.localStorage.getItem('currentNetwork')
// this.$store?.state?.wallet?.accounts[0]

import AppConfig from '@/App.Config.js'
import { ETH_RPC_ENDPOINT,BSC_RPC_ENDPOINT, MATIC_RPC_ENDPOINT,
	BSC_RPC_ENDPOINT_PROD, ETH_RPC_ENDPOINT_PROD, MATIC_RPC_ENDPOINT_PROD,
	renderNetworkName, FORTMATIC, COINBASE, METAMASK, BITSKI, WALLETCONNECT,
  checkProviderWallet } from '@/features/Common.js'
import ApproveContract from '@/components/ApproveContract.vue';
var gConfig = AppConfig()

export default {
	name: 'ApprovePage',
	components: {
		ApproveContract
	},
	data() {
		return {
			curAccount: undefined,
			curNetwork: undefined,
      operatorAddr: "",
			addrs: [],
      showApprove: false,
      approveImg: require('../assets/img/approveOnOffChain/unaprroved.png'),
      showNetwork:false,
      checkedNetwork: undefined
		}
	},
	computed: {
		account() {
			return this.$store?.state?.wallet?.accounts[0];
		},
		network() {
			return window.localStorage.getItem('currentNetwork');
		},
    signed() {
			this.checkCurrentNetwork();
			return this.account != undefined;
		},
	},
	watch: {
		account (val, oldVal) {
			this.curAccount = val;
			this.login();
		},
		network(val, oldVal) {
			this.curNetwork = val;
		}
	},  
	methods: {
		login() {
			if(this.curAccount == undefined) {
				return;
			}
      let data = {};
      // console.log("this.curNetwork", this.curNetwork);
			switch(renderNetworkName(this.curNetwork)) {
				case 'ETH': 
        this.checkedNetwork = 'Ethereum'
					data = this.$store.getters.getRequireOnOffChainApprove("eth")
          break;
				case 'BSC': 
        this.checkedNetwork = 'BSC'
					data = this.$store.getters.getRequireOnOffChainApprove("bsc")
					break;
				case 'POL': 
				default:
					data = {};
			}

      const types = Object.keys(data);
      types.forEach(type => {
        if(type == "operatorAddr") {
          this.operatorAddr = data[type];
        } else {
          const names = Object.keys(data[type]);
          names.forEach(name => {
            if(name == "operatorAddr") {
              operatorAddr = data[type][name]
            } else {
              this.addrs.push({
                name,
                address: data[type][name],
                type
              })
            }
          })
        }
    })
		},
    clickShowApprove() {
      this.showApprove = !this.showApprove;
      if(this.showApprove) {
        this.approveImg = require('../assets/img/approveOnOffChain/aprroved.png');
        const approveImg = this.$refs.approveImg;
        if(approveImg != null && approveImg.style) {
          approveImg.style.width = `189px`;
          approveImg.style.marginLeft = `612px`;
        }
      } else {
        this.approveImg = require('../assets/img/approveOnOffChain/unaprroved.png');
        const approveImg = this.$refs.approveImg;
        if(approveImg != null && approveImg.style) {
          approveImg.style.width = `201px`;
          approveImg.style.marginLeft = `600px`;
        }
      }
    },  
    myEventHandler(e) {
      const width = document.body.offsetWidth;
      const rate = width / 1920;
      const walletConnectPage = this.$refs.walletConnectPage;
      if(walletConnectPage != null && walletConnectPage.style) {
        walletConnectPage.style.width = `${width}px`;
      }

      const info = this.$refs.info;
      if(info != null && info.style) {
        info.style.transform = `translate(-50%, -50%) scale(${rate}, ${rate})`;
      }
    },
    async goApp() {
      window.location.href = `dvisionnetwork://walletConnect`;
    },
    onClickItem(network) {
			const loginBy = window.localStorage.getItem('loginBy')
			if (loginBy === COINBASE) {
				this.mxShowToast('Please change network on extension or mobile app, thank you.')
				return;
			}
			if(gConfig.isProd) {
				switch (network) {
					case 'ETH':
						this.switchNetwork('0x1', 'Ethereum', ETH_RPC_ENDPOINT_PROD)
						break
					case 'BSC':
						this.switchNetwork('0x38', 'BSC', BSC_RPC_ENDPOINT_PROD)
						break
				}
			} else {
				switch (network) {
					case 'ETH':
						this.switchNetwork('0x5', 'Ethereum', ETH_RPC_ENDPOINT)
						break
					case 'BSC':
						this.switchNetwork('0x61', 'BSC', BSC_RPC_ENDPOINT)
						break
				}
			}
		},
    async switchNetwork(chainId, name, rpc) {
			const loginBy = window.localStorage.getItem('loginBy')
			try {
				if(loginBy === BITSKI) {
					window.localStorage.setItem('networkRPC', rpc)
					window.localStorage.setItem('fortmaticNetwork', chainId)
					window.localStorage.setItem('currentNetwork', chainId)
					window.location.reload()
				} else if(loginBy === FORTMATIC) {
					if(name !== 'Ethereum') {
					this.mxShowToast('Formatic only supports Ethereum network')
					}
				} else if(loginBy === METAMASK || loginBy === COINBASE) {
					const provider = checkProviderWallet(loginBy);
					await window.ethereum.request({
						method: 'wallet_switchEthereumChain',
						params: [{ chainId: chainId }],
					})
					this.checkedNetwork = name
				}
				else if(loginBy === WALLETCONNECT) {
					this.mxShowToast('Please change network on extension or mobile app, thank you')
				}

			} catch (e) {
				// console.log('e',e)
				this.mxShowToast(e.message)
			}
		},
    async checkCurrentNetwork() {
			let currentNetwork = window.localStorage.getItem('currentNetwork')
			const loginBy = window.localStorage.getItem('loginBy')
			if(loginBy === FORTMATIC) {
				currentNetwork = window.localStorage.getItem('fortmaticNetwork')
			}
			if(loginBy === WALLETCONNECT) {
				const walletConnect = window.localStorage.getItem('walletconnect')
				this.chainId = JSON.parse(walletConnect).chainId
				currentNetwork = this.chainId.toString()
			}
			try {
				switch (currentNetwork) {
          case '0x5':
					case '5':
					case '0x1':
					case '1':
						this.checkedNetwork = 'Ethereum'
						break
					case '0x61':
					case '97':
					case '0x38':
					case '56':
						this.checkedNetwork = 'BSC'
						break
					case '0x13881':
					case '80001':
					case '0x89':
					case '137':
						this.checkedNetwork = 'Polygon'
						break
				}
			} catch (err) {
				// console.log('err', err)
			}
		},
	},
  beforeCreate() {
    this.$store.dispatch('showGnb',false);
  },
  async mounted() {
    this.myEventHandler(null);
    window.addEventListener("resize", this.myEventHandler);
    this.curAccount = this.$store?.state?.wallet?.accounts[0];
    this.curNetwork = window.localStorage.getItem('currentNetwork');
    if(this.curAccount == undefined) {
      this.$store.dispatch('showLoginPopup', true);
    } else {
      this.login();
    }
  },
  destroyed() {
    this.$store.dispatch('showGnb',true);
  }
}
</script>

<style lang="scss" scoped>
.background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;

  .info-network {
    position: absolute;
    left: 35px;
    top: 20px;
    margin-left: gREm(16);
    cursor: pointer;
    z-index: 12;

    .network-icon {
      font-family: $AppFont;
    }
    .network-popup {
      display: flex;
      gap: gREm(30);
      border-radius: gREm(8);
      margin-top: gREm(16);
      position: absolute;
      background-color: #ffffff;
      padding: gREm(10) gREm(20);
      left: gREm(-37.5);
    }

    .network-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .icon {
        width: gREm(30);
        height: gREm(30);
        margin-bottom: gREm(8);
      }

      label {
        color: #000000;
        font-size: gREm(14);
        line-height: gREm(22);
        font-family: $AppFont;
      }
    }
  }

  .backgroundImg-Blur {
    position: absolute;
    z-index: 10;
    height: 100%;
    width: auto;
    left: 50%;
    transform: translateX(-50%);
    content:url('../assets/img/approveOnOffChain/background.png');
    filter: blur(5px);
    -webkit-filter: blur(5px);
  }

  .container {
    position: absolute;
    width: 100vw;
    height: 100vh;

    .backgroundImg {
      position: absolute;
      z-index: 10;
      width: 100%;
      height: auto;
      top: 50%;
      transform: translateY(-50%);
      content:url('../assets/img/approveOnOffChain/background.png');
    }
    .walletConnectPage {
      position: relative;
      width: 100vw;
      height: 100vh;
      z-index: 11;

      font-family: Montserrat, sans-serif;

      .info {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 864px;
        height: auto;

        .infoContainer {
          position: relative;
          width: 100%;
          height: 100%;
          padding-left: 55px;
          padding-top: 281px;

          .clickShowApprove {
            margin-left: 600px;
            cursor: pointer;
            width: 201px;
            height: 36px;
          }

          .contractList {
            margin-top: 37px;
            width: 789px;
            height: 302px;
            overflow: scroll;
            overflow-x: hidden; 
            padding-top: 35px;
            mask-image: url('../assets/img/approveOnOffChain/mask.png');
            -webkit-mask-image: url('../assets/img/approveOnOffChain/mask.png');
            &::-webkit-scrollbar              {
              width: 26px;
              background: url('../assets/img/approveOnOffChain/background_scroll.png');
             }

            &::-webkit-scrollbar-button {
              width: 23px;
              height: 32px;
            }
            &::-webkit-scrollbar-button:single-button:vertical:decrement       { 
              background: url('../assets/img/approveOnOffChain/scroll_up.png') no-repeat center 50%;
            }

            &::-webkit-scrollbar-button:single-button:vertical:increment {
              background: url('../assets/img/approveOnOffChain/scroll_down.png') no-repeat center 50%;
            }
           
            &::-webkit-scrollbar-thumb        { 
              width: 18px;
              height: 38%;
              background: url('../assets/img/approveOnOffChain/scrollbar.png');
              background-repeat: no-repeat;
              background-position-x: 4px;
             }
          }

          .goApp {
              margin-left: 460px;
              width: 347px;
              height: 67px;
              margin-top: 36px;
              right: 0;
              @include SetBgImage(
                url('../assets/img/approveOnOffChain/go_app_default.png')
              );

              &:hover {
                @include SetBgImage(
                    url('../assets/img/approveOnOffChain/go_app_hover.png')
                );
              }
            }
        }
       
      }
    }
  }
}
</style>
