<template>
  <div class="approveContract" v-if="appear">
    <div class="number">
      {{ number }}
    </div>
    <div class="name">
      {{ item.name }}
    </div>
    <div :class="{'apporved': approve == '1', 'unApproved': approve == '0', 'button' : true  }"  ref="button" :disable="disable" @click="setApprove">

    </div>
  </div>
</template>

<script>
import WalletAPI from '@/features/WalletAPI.js'
var wAPI = new WalletAPI();

export default {
  name: 'ApproveContract',
  props: {
    item: {
      name: String,
      address: String,
      type: String
    },
    number: Number,
    showApprove: Boolean,
    operatorAddr: String
  },
  data() {
    return {
      approve: -1,
      process: false
    }
  },
  computed: {
    appear() {
      if(this.approve == -1) {
        return false;
      } else {
        if(this.showApprove) {
          return this.approve == 1;
        } else {
          return this.approve == 0;
        }
      }
    },
    disable() {
      return this.approve != 0 || this.process;
    }
  },
  methods: {
    async allowance() {
      this.approve = await wAPI.allowance(
        this.item.type,
        this.item.address,
        this.$store?.state?.wallet?.accounts[0],
        this.operatorAddr
      );
    },
    async setApprove() {
      if(this.approve == 1) {
        // this.process = true;
        // const receipt = await wAPI.approve(
        //   this.item.type,
        //   this.item.address,
        //   this.$store?.state?.wallet?.accounts[0],
        //   this.operatorAddr,
        //   false
        // );
        // console.log("receipt", receipt);
        // this.process = false;
        // if(receipt && receipt.status) {
        //   this.approve = 0;
        // }
        return;
      }
      this.process = true;
      const receipt = await wAPI.approve(
        this.item.type,
        this.item.address,
        this.$store?.state?.wallet?.accounts[0],
        this.operatorAddr,
        true
      );
      // console.log("receipt", receipt);
      this.process = false;
      if(receipt && receipt.status) {
        this.approve = 1;
      }
    },
    async sleep(ms) {
      return new Promise((resolve) => {
          setTimeout(resolve, ms);
      })
    },
  },
  async mounted() {
    while(true) {
      try {
        await this.allowance();
        break;
      } catch(e) {
        await this.sleep(1000);
        continue;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.approveContract {
  width: 100%;
  height: 49px;
  padding-left: 45px;

  .number {
    display: inline-block;
    width: 69px;
    height: 40px;
    vertical-align: middle;
    text-align: center;
    margin: 0 auto 3px;
    line-height: 40px;
    @include SetBgImage(
      url('../assets/img/approveOnOffChain/background_number.png')
    );
  }

  .name {
    display: inline-block;
    width: 333px;
    height: 68px;
    vertical-align: middle;
    margin-left: -6px;
    text-align: center;
    line-height: 68px;
    font-family: Montserrat, sans-serif;
    color: black;
    font-weight: bold;
    @include SetBgImage(
      url('../assets/img/approveOnOffChain/background_text.png')
    );
  }

  .button {
    cursor: pointer;
    display: inline-block;
    width: 319px;
    height: 64px;
    vertical-align: middle;
    margin-left: -16px;
   
  }

  .apporved {
    margin-bottom: 5px;
    @include SetBgImage(
      url('../assets/img/approveOnOffChain/aprroved_default.png')
    );

    :hover {
      @include SetBgImage(
        url('../assets/img/approveOnOffChain/aprroved_hover.png')
      );
    }
  }

  .unApproved {
    @include SetBgImage(
      url('../assets/img/approveOnOffChain/unaprroved_default.png')
    );

    :hover {
      @include SetBgImage(
        url('../assets/img/approveOnOffChain/unaprroved_hover.png')
      );
    }
  }
}
</style>
